import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  state: {
    memberData: null
  },
  mutations: {
    setMemberData(state, data) {
      state.memberData = data
    }
  },
  actions: {
  },
  modules: {
  }
})
